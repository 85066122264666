<template>
  <div>
    <!--Результаты поиска-->
    <div v-if="showResult">
      <v-divider />
      <div class="overline mb-4">
        Найдено результатов {{ objResultUser.length }}:
      </div>
      <template>
        <div v-for="obj in objResultUser" :key="obj.clinicDepartmentServiceId">
          <v-card class="media" hover outlined>
            <div class="media-body">
              <v-row>
                <v-col
                  cols="3"
                  sm="2"
                  class="d-flex justify-center align-center flex-column"
                >
                  <v-avatar class="profile" color="grey" size="64" tile>
                    <v-img
                        v-if="obj.doctorAvatar"
                        :src="apiAddress + obj.doctorAvatar"
                    ></v-img>
                    <v-img v-else src="~images/base_img_doctor.webp"></v-img>
                  </v-avatar>
                  <v-rating
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      hover
                      :value="obj.userRating"
                      readonly
                      size="30"
                      length="3"
                      background-color="primary"
                  ></v-rating>
                </v-col>
                <v-col
                  cols="8"
                  sm="4"
                  class="d-flex justify-start align-center"
                >
                  <div>
                    <router-link
                      :to="{
                        path: '/doctor/' + obj.doctorId,
                        params: { userId: obj.doctorId },
                      }"
                      target="_blank"
                    >
                      <h5 class="media-heading" itemprop="description">
                        {{ obj.doctorName }}
                      </h5>
                      <div class="doctor-specialization">
                        {{ objSpecializationNames }}
                      </div>
                    </router-link>
                    <template v-if="$resize && $mq.above(577)">
                      <router-link
                        :to="{
                          path: '/department/' + obj.department_id,
                          params: { depId: obj.department_id },
                        }"
                        target="_blank"
                      >
                        <span :class="b('department')"
                          >{{ obj.departmentName }}, {{ obj.cityPart }}</span
                        >
                      </router-link>
                      <template v-if="obj.distance">
                        <span :class="b('distance')">
                          До этого врача {{
                            Math.floor(obj.distance.kilometers * 100) / 100
                          }} километра
                        </span>
                      </template>
                    </template>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex justify-start align-center"
                >
                  <div>
                    <template v-if="obj.serviceName && isFirstService">
                      <template v-if="$resize && $mq.between([0, 576])">
                        <router-link
                          :to="{
                            path: '/department/' + obj.department_id,
                            params: { depId: obj.department_id },
                          }"
                          target="_blank"
                        >
                          <span :class="b('department')"
                            >{{ obj.departmentName }}, {{ obj.cityPart }}</span
                          >
                        </router-link>
                        <template v-if="obj.distance">
                          <span :class="b('distance')">
                            До этого врача {{
                              Math.floor(obj.distance.kilometers * 100) / 100
                            }} километра
                          </span>
                        </template>
                      </template>

                      <span :class="b('price')" itemprop="name"
                        >{{ obj.serviceName }}:<br />
                      </span>
                      <span :class="b('price')" itemprop="price">
                        <b>{{
                          obj.doctorServicePrice || obj.servicePrice
                        }}</b></span
                      >
                      <br />
                    </template>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                  class="text-center btn-container d-flex flex-row flex-sm-column align-center justify-center"
                >
                  <v-btn
                    outlined
                    class="btn-detail mr-2 mb-0 mb-sm-2 mr-sm-0"
                    v-if="obj.hiddenLk == 0"
                    color="primary"
                    @click="openDrawerDetail(obj.department_id)"
                  >
                    Подробнее
                  </v-btn>

                  <v-btn
                    outlined
                    class="btn-order"
                    color="success"
                    v-if="isFirstService && obj.hiddenLk == 0"
                    @click="openDrawerFast(obj)"
                  >
                    Записаться
                  </v-btn>
                  <v-btn
                    outlined
                    color="red"
                    v-if="isFirstService && obj.hiddenLk == 1"
                    class="fz-10"
                    @click="notAvailable"
                  >
                    Запись ограничена
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </template>
    </div>
    <!--Если результатов поиска не найдено-->
    <div v-show="showNotResult">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-else>
        <not-found />
      </div>
    </div>
  </div>
</template>
//тест2
<script>
import * as names from "store/names";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import NotFound from "./MainResultNotFound";
import {mdiStar, mdiStarOutline} from "@mdi/js";

const COMPONENT_NAME = "main-result-user";
export default {
  name: COMPONENT_NAME,
  props: ["data"],
  components: { NotFound },
  created() {
    this.searchEntity(false);
  },
  data: () => ({
    value: [],
    result: [],
    loading: false,
    isFirstService: true,
    icons: {
      mdiStarOutline,
      mdiStar,
    },

  }),
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 3000);
  },
  watch: {
    selectedSortValue() {
      this.searchEntity(true);
    },
    selectedTypeValue() {
      this.searchEntity(true);
    },
    selectedPrice() {
      this.searchEntity(true);
    },
    selectedCityParts() {
      this.searchEntity(true);
    },
    selectedMyCoordinatesLong() {
      this.searchEntity(true);
    },
    selectedMyCoordinatesLat() {
      this.searchEntity(true);
    },
  },
  computed: {
    ...mapGetters({
      selectedSortValue: names.SELECTED_SORT_VALUE,
      selectedTypeValue: names.SELECTED_TYPE_VALUE,
      selectedCityParts: names.SELECTED_CITY_PARTS,
      selectedPrice: names.SELECTED_PRICE_VALUE,
      selectedMyCoordinatesLat: names.SELECTED_MY_COORDINATES_LAT,
      selectedMyCoordinatesLong: names.SELECTED_MY_COORDINATES_LONG,
      objResultUser: names.OBJ_SEARCH_SERVICE_BY,
      objSpecializationNames: names.OBJ_SEARCH_SERVICE_SPEC,
      docId: names.DOCTOR_ID,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),

    apiAddress() {
      return names.API_ADDRESS;
    },

    showResult() {
      return !isEmpty(this.objResultUser);
    },
    showNotResult() {
      return this.data.entityName !== null && isEmpty(this.objResultUser);
    },
  },
  methods: {
    ...mapActions({
      mainResultUser:
        names.ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_FIRST_SERVICE_BY_DOCTOR_ID,
      selectedDoctorInfo: names.ACTION_SAVE_SELECTED_DOCTOR_INFO,
      saveDepId: names.ACTION_SAVE_DEPARTMENT_ID,
      clearResult: names.ACTION_CLEAR_OBJ_SERVICE_BY,
    }),
    async searchEntity(update) {
      if (update === false) {
        this.clearResult();
      }
      this.mainResultUser({
        doctorId: this.data.entityId,
        cityId: this.selectedCity.id,
        sort: this.selectedSortValue,
        filterChildren: this.selectedTypeValue,
        filterPriceStart: this.selectedPrice[0],
        filterPriceEnd: this.selectedPrice[1],
        cityPart: this.selectedCityParts,
        myCoordinatesLat: this.selectedMyCoordinatesLat,
        myCoordinatesLong: this.selectedMyCoordinatesLong,
        update: update,
      });
    },

    async saveSelectDoctorInfo(obj) {
      this.selectedDoctorInfo({
        doctorId: obj.doctorId,
        doctorName: obj.doctorName,
        cityId: this.selectedCity.id,
        serviceName: obj.serviceName,
        serviceId: obj.serviceId,
        servicePrice: obj.servicePrice,
        doctorServicePrice: obj.doctorServicePrice,
        departmentName: obj.departmentName,
        departmentId: obj.department_id,
        departmentPhone: obj.departmentPhone,
        departmentAddress: obj.departmentAddress,
        cityPart: obj.cityPart,
        childDoc: obj.childrensMd,
      });
    },

    openDrawerDetail(depId) {
      this.saveDepId(depId);

      this.$drawer.showDrawer(true, "Detail");
      document.querySelector("body").style.overflow = "hidden";
    },

    openDrawerFast(obj) {
      this.saveSelectDoctorInfo(obj);
      document.querySelector("body").style.overflow = "hidden";
      this.$drawer.showDrawer(true, "Fast");
    },
    notAvailable(){
      return this.$notifier.showMessage("В данный момент запись к данному доктору не возможна!", "error");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

span {
  color: #000;
  font-family: $sanspro;
  font-size: 14px;
}
.doctor-specialization {
  color: $grey-blue;
  font-family: $sanspro;
  font-size: 14px;
}
.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }

  a {
    text-decoration: none;
  }

  .media-heading {
    font-size: 20px;
    @include smaller-phone {
      padding-left: 20px;
    }
  }

  .btn-container {
    @include smaller-phone {
      margin-top: 20px;
    }
    button {
      width: 120px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
